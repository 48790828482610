import * as React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const BottomSlide = (): JSX.Element => {
  const { order: orderState } = useSelector((state: CR.RootState) => state.order);

  const order = orderState as CR.OrderCRUD;

  const Timeline = (): JSX.Element => (
    <>
      { order.route_points?.map((point, index, array) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="trip" key={index}>
          { index !== 0 && <div className="line-up" />}

          <div className={`dot${index === 0 ? '-start' : ''}`} />

          { index !== (array.length - 1) && <div className="line-down" />}

          <div className="routePoint">
            <div className="title">
              {point.name}
            </div>
            <div className="address">
              {point.address_name}
            </div>
          </div>
        </div>
      )) }
    </>
  );

  return (
    <div className="secondSlide">
      <div className="header">
        <FormattedMessage id="trip" />
      </div>

      <div className="tripDetails">

        <Timeline />

      </div>

      <a href="https://meridiantaxi.ro/" aria-label="install_meridian" target="_blank" rel="noreferrer" className="actionButton">
        <FormattedMessage id="install_meridian" />
      </a>
    </div>
  );
};

export default BottomSlide;
